/* coloring guidance */

:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Foundation-White2: #fcfcfc;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Error-50: #eb5757;
}

.divContainer {
  margin: 12px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.divUserName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  padding: 6px;
  align-self: stretch;
}
.divCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.divDetailPie {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
}

.responsiveChartContainer {
  width: 100%;
}
.appBarDashboard {
  box-shadow: none;
  border-bottom: 1px solid var(--Border-10);
  background: var(--Foundation-White2);
  color: var(--Foundation-200);
  min-height: 0;
  padding: 12px 16px;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  min-height: 0;
  padding: 0;
  margin: 0;
}
.card {
  width: 100%;
  /* width: 33%; */
  box-shadow: none !important;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.spaceBetweenRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 12px;
}

.spaceBetweenFullRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  width: 100%;
}

.grayLine {
  border-bottom: 1px solid var(--Border-10);
  width: 100%;
}

.divRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.drawerDashboard {
  border-right: 1px solid var(--Border-10);
  background: var(--Foundation-White2);
}

.popoverUserSetting {
  position: absolute;
  top: 20px;
}

.paperUserSetting {
  border-radius: 8px;
  border: 1px solid var(--Border-10, #cfdfec);
  background: var(--Foundation-100, #fff);

  /* Shadow 1 */
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05),
    0px -4px 15px 0px rgba(0, 0, 0, 0.05);
}

.listItemUser {
  padding: 6px;
  width: 280px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.optionButton {
  display: flex;
  text-transform: none;
}
.optionButton:hover,
.optionButton:active,
.optionButton:focus {
  background-color: transparent;
  box-shadow: none;
  color: var(--Foundation-100);
}

.itemUserButton {
  /* Inter/Body Text/Regular/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  border-radius: 8px;
  padding: 8px 6px;
  gap: 8px;
}

.imageLogo {
  width: auto;
  height: 25px;
  flex-shrink: 0;
}

.box1 {
  display: flex;
  align-items: center;
}

.box2 {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--Secondary-60);
}

.listSpace {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 90%;
}

.helpSpace {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.textBold24 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold16 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold12 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold14 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold10 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textRegular14 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textRegular10 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/10 */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  letter-spacing: 0.1px;
}

.textRegularWhite14 {
  color: var(--Foundation-100);

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textRegularGray14 {
  color: var(--Grey-60);

  /* Inter/Body Text/Regular/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textError50 {
  color: var(--Error-50);

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textSemiRegularGray14 {
  color: var(--Grey-60);

  /* Inter/Body Text/Regular/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textGray16Color60 {
  color: var(--Grey-60);

  /* Inter/Body Text/Medium/16 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textGrayRegular14Color60 {
  color: var(--Grey-60);

  /* Inter/Body Text/Medium/16 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}
.customTooltip {
  display: flex;
  padding: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: var(--Foundation-200);
  color: var(--Foundation-100);
}
.customAreaTooltip {
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  border-radius: 8px;
  background: var(--Foundation-200);
  color: var(--Foundation-100);
  width: 180px;
}

.tooltipCircle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 8px;
  padding: 0;
}

.tooltipLabel {
  font-size: 14px;
}
.tooltipRowItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}
/* <!--Portrait mode of a smart phone--> */
@media screen and (max-width: 480px) and (orientation: portrait) {
  .divHeader {
    padding: 20px 0;
  }
  .imageLogo {
    width: auto;
    height: 20px;
  }
  .divForm {
    width: 100%;
  }
  .textRegular14 {
    font-size: 10px;
  }
  .textRegularWhite14 {
    font-size: 10px;
  }
  .textSemiRegularGray14 {
    font-size: 8px;
  }
  .spaceBetweenRow {
    gap: 4px;
  }
}

/* <!--Landscape mode of a smart phone and a small tablet--> */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .divHeader {
    padding: 20px 0;
  }
  .imageLogo {
    width: auto;
    height: 20px;
  }
  .divForm {
    width: 100%;
  }
  .textRegular14 {
    font-size: 12px;
  }
  .textRegularWhite14 {
    font-size: 12px;
  }
  .textSemiRegularGray14 {
    font-size: 12px;
  }
}

/* <!--Tablet and a small laptop--> */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
