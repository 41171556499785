/* coloring guidance */

:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Red-50: #eb5757;
}

.root {
  padding: 32px;
  background-image: url("./background.svg");
  /* width: 100%; */
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.divFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 32px;
}

.divHeader {
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divFooter {
  display: flex;
  padding: 32px 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.divForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 450px;
}

.divFormField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.divButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 16px 0;
}

.imageLogo {
  width: auto;
  height: 40px;
  flex-shrink: 0;
}

.outlinedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--Primary-100);
  background-color: var(--Foundation-100);
  text-transform: none;

  &:hover {
    background: var(--Foundation-100);
  }
}

.containedButton {
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-transform: capitalize;
  border-radius: 8px;
  background: var(--Primary-100, #00539b);

  &:hover {
    background: var(--Primary-100);
  }
}

.disabledContainedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Grey-20);
  text-transform: capitalize;

  &:hover {
    background: var(--Grey-20);
  }
}

.textOutlinedButton {
  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textContainedButton {
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textDisabledContainedButton {
  color: var(--Grey-50);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textRegular24 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textRegular18 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Medium/18 */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.252px;
}

.textRegular16 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textRegular14 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textLinkPrimary16 {
  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  text-decoration: none;
}

.textLinkRegular14 {
  color: var(--Secondary-60);

  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.084px;
  text-decoration: none;
}

.textGray14 {
  color: var(--Grey-50);
  text-align: center;

  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textAlert12 {
  color: var(--Red-50);
  text-align: center;

  /* Inter/Body/Regular/14 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.field {
  display: flex;
  font-family: Inter;
  /* height: 48px; */
  padding: 12px;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.textField {
  color: var(--Foundation-200, #3c3c3c);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  padding: 0;
}

.textField::placeholder {
  color: var(--Grey-50, #999);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.tooltipStyle {
  color: var(--Secondary-60);
  background-color: var(--Secondary-10);
  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.visibilityIcon {
  color: var(--Secondary-60);
  background-color: transparent;
}

/* <!--Portrait mode of a smart phone--> */
@media screen and (max-width: 480px) and (orientation: portrait) {
  .divHeader {
    padding: 20px 0;
  }
  .imageLogo {
    width: auto;
    height: 20px;
  }
  .divForm {
    width: 100%;
  }
  .textRegular24 {
    font-size: 18px;
  }
  .textRegular18 {
    font-size: 14px;
  }
  .textRegular16 {
    font-size: 12px;
  }
  .textRegular14 {
    font-size: 10px;
  }
  .textLinkRegular14 {
    font-size: 10px;
  }
  .textGray14 {
    font-size: 10px;
  }
}

/* <!--Landscape mode of a smart phone and a small tablet--> */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .divForm {
    width: 100%;
  }
}

/* <!--Tablet and a small laptop--> */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* <!--PC browser--> */
/* @media screen and (min-width: 1024px) {} */
