.searchWidth {
  width: 300px;
}

.divGroupButton {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  width: 500px;
  margin: 32px 0 0 0;
}
