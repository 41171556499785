:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Red-50: #eb5757;
}

.divContainer {
  margin: 12px 0;
  background-color: var(--Foundation-100);
}

.textTitle {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
  color: var(--Foundation-200);
}

.textSubtitle {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Grey-60);
}

.divTableData {
  padding: 32px 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paddingContentDetail {
  padding: 0 24px 24px 24px;
}

.divLabelandText {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.textLabelDetail {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Grey-60);
}

.textValueDetail {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Foundation-200);
}

.searcProducthWidth {
  width: 250px;
  padding-right: 10px;

  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.084px;
}

.searchDateWidth {
  width: 320px;
}
