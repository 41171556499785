:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Red-50: #eb5757;
}

.root {
  padding: 32px;
  background-image: url("./background.svg");
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: center; */
  background-color: var(--Foundation-100);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.divHeader {
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageLogo {
  width: auto;
  height: 30px;
  flex-shrink: 0;
}

.divContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 32px;
  width: 40%;
  margin-top: 5%;
  margin-left: 3%;
}

.sizeIcon {
  width: 100px;
  height: 100px;
}

.textTitle {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.456px;
  color: var(--Foundation-200);
}

.textSubtitle {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  color: var(--Grey-60);
}

.styleButton {
  border-radius: 8px;
  background: var(--Primary-100);
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;

  &:hover {
    background: var(--Primary-100);
  }
}

.textButton {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  color: var(--Foundation-100);
}

.divFooter {
  position: fixed;
  bottom: 0;
  padding: 32px;
}

.textCopyright {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Grey-60);
}

/* <!--Portrait mode of a smart phone--> */
@media screen and (max-width: 400px) and (orientation: portrait) {
  .divContent {
    width: 60%;
    margin-top: 10%;
  }

  .textButton {
    font-size: 12px;
  }

  .textTitle {
    font-size: 18px;
  }

  .textSubtitle {
    font-size: 14px;
  }

  .textCopyright {
    font-size: 10px;
  }
}

/* <!--Landscape mode of a smart phone and a small tablet--> */
@media screen and (min-width: 401px) and (max-width: 767px) {
  .divContent {
    width: 60%;
    margin-top: 30%;
  }

  .textButton {
    font-size: 12px;
  }

  .textTitle {
    font-size: 18px;
  }

  .textSubtitle {
    font-size: 14px;
  }

  .textCopyright {
    font-size: 10px;
  }
}

/* <!--Tablet and a small laptop--> */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .textSubtitle {
    font-size: 20px;
  }
  .textButton {
    font-size: 18px;
  }
  .divContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 32px;
    width: 53%;
    margin-top: 30%;
    margin-left: 3%;
  }
}

@media screen and (height: 600px) and (width: 1024px) {
  .divContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 32px;
    width: 37%;
    margin-top: 0%;
    margin-left: 3%;
  }
  .textSubtitle {
    font-size: 20px;
  }
  .textButton {
    font-size: 18px;
  }
}

@media screen and (orientation: landscape) {
  .divContent {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .divFooter {
    position: relative;
    bottom: 0;
    padding: 32px;
  }

  .textTitle {
    font-size: 20px;
  }

  .textSubtitle {
    font-size: 16px;
  }
}

@media screen and (orientation: landscape) and (min-width: 1024px) {
  .divContent {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .divFooter {
    position: fixed;
    bottom: 0;
    padding: 32px;
  }

  .textTitle {
    font-size: 20px;
  }

  .textSubtitle {
    font-size: 16px;
  }
}
