:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Red-50: #eb5757;
}

.divContainer {
  margin: 12px 0;
  background-color: var(--Foundation-100);
}

.divForTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.textTitle {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
  color: var(--Foundation-200);
}

.textSubtitle {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Grey-60);
}

.divTableData {
  padding: 32px 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.searchWidth {
  width: 300px;
}

.searchDateWidth {
  width: 300px;
}

.divFlexMerchant {
  display: flex;
  align-items: center;
  gap: 10px;
}

.widthLogoMerchant {
  width: 20px;
}

.divFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tableHead {
  border-top: 1px solid var(--Border-10);
  border-bottom: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.tableRow {
  border-bottom: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.tableCellFullBorder {
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.textLabelHeader {
  color: var(--Foundation-200);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.084px;
}

.textValueTable {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.paddingContentDetail {
  padding: 0 24px 24px 24px;
}

.buttonViewDetail {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Secondary-10);
  text-transform: none;
  color: var(--Primary-100);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.divLabelandText {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.leaflet_container {
  height: 300px;
  width: 90%;
}

.textLabelDetail {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Grey-60);
}

.textValueDetail {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  color: var(--Foundation-200);
}
