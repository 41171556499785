/* coloring guidance */

:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-20: #bae0ff;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Red-50: #eb5757;
}

.divContainer {
  margin: 12px 0;
  background-color: #fff;
}

.divTableData {
  padding: 32px 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.divDialogEdit {
  padding: 24px;
}

.divCloseEnd {
  display: flex;
  justify-content: flex-end;
}

.divDialogEditTitle {
  /* text-align: center; */
  padding: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.divFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 32px;
}

.divHeader {
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divFooter {
  display: flex;
  padding: 32px 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.divBoxDrawer {
  margin: 100px 0 0 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 550px;
}

.divRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divRow16 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.divRowStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 16px;
  border: 1px solid var(--Border-10, #cfdfec);
}

.divForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 450px;
}

.divFormField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.divButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 16px 0;
}

.divFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.divLogoCompany {
  width: 105px;
}

.divFullLogo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.divListProvider {
  padding: 0 24px 24px 24px;
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.divItemProvider {
  margin: 4px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.divViewField {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 0px;
}

.divGroupButton {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 32px 0 0 0;
}

.divInfo {
  display: flex;
  padding: 12px;
  margin: 0 0 32px 0;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Secondary-20);
  background: var(--Secondary-10);
}

.divColumnFlexEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.startAdornmentNumber {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin: 0 8px 0 0;
}

.tableHead {
  border-top: 1px solid var(--Border-10);
  border-bottom: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.tableRow {
  border-bottom: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.tableCellFullBorder {
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
  height: 80px;
}
.imageLogo {
  width: auto;
  height: 40px;
  flex-shrink: 0;
}

.threeDotsButton {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  text-transform: none;

  &:hover {
    background: none;
  }
}
.listItemAction {
  padding: 0;
  width: auto;
}

.listItemIcon {
  min-width: 30px;
}

.itemActionButton {
  /* Inter/Body Text/Regular/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  border-radius: 8px;
}

.grayLine {
  border-bottom: 1px solid var(--Border-10);
  width: 100%;
}

.popoverUserSetting {
  position: fixed;
  top: 40px;
  left: -50px;
}

.paperUserSetting {
  border-radius: 8px;
  border: 1px solid var(--Border-10, #cfdfec);
  background: var(--Foundation-100, #fff);

  /* Shadow 1 */
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05),
    0px -4px 15px 0px rgba(0, 0, 0, 0.05);
}

.outlinedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--Primary-100);
  background-color: var(--Foundation-100);
  text-transform: none;

  &:hover {
    background: var(--Foundation-100);
  }
}

.containedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Primary-100);
  text-transform: capitalize;
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;

  &:hover {
    background: var(--Primary-100);
  }
}

.containedButtonSmall {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: auto;
  align-self: center;
  border-radius: 8px;
  background: var(--Primary-100);
  text-transform: capitalize;
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;

  &:hover {
    background: var(--Primary-100);
  }
}

.disabledContainedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Grey-20);
  text-transform: capitalize;

  color: var(--Grey-50);
  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;

  &:hover {
    background: var(--Grey-20);
  }
}

.editButton {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Secondary-10, #ecf5ff);
  text-transform: none;

  color: var(--Primary-100, #00539b);
  text-align: center;

  /* Inter/Body Text/Semi Bold/12 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
}

.textOutlinedButton {
  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textContainedButton {
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textDisabledContainedButton {
  color: var(--Grey-50);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textBold24 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold18 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textBold16 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textBold14 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textSemiBold14 {
  color: var(--Foundation-200);

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.textSemiBold12 {
  color: var(--Foundation-200);
  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.textRegular24 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textRegular18 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Medium/18 */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: -0.252px;
}

.textRegular16 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textRegular14 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textLinkPrimary16 {
  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  text-decoration: none;
}

.textLinkRegular14 {
  color: var(--Secondary-60);

  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.084px;
  text-decoration: none;
}

.textGray16Color60 {
  color: var(--Grey-60);

  /* Inter/Body Text/Medium/16 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textGray14Color60 {
  color: var(--Grey-60);

  /* Inter/Body Text/Medium/16 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textGray14 {
  color: var(--Grey-50);
  text-align: center;

  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textGray12 {
  color: var(--Grey-60);

  /* Inter/Body Text/Regular/12 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.textAlert12 {
  color: var(--Red-50);
  text-align: center;

  /* Inter/Body/Regular/14 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.field {
  display: flex;
  /* height: 48px; */
  padding: 12px;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.placeholder {
  color: var(--Grey-60);
  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.tooltipStyle {
  color: var(--Secondary-60);
  background-color: var(--Secondary-10);
  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.visibilityIcon {
  color: var(--Secondary-60);
  background-color: transparent;
}

.addressField {
  display: flex;
  height: 128px;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.logoCompany {
  height: 100%;
  width: 100%;
}

.infoIcon {
  width: 18px;
  height: 18px;
}

/* <!--Portrait mode of a smart phone--> */
@media screen and (max-width: 480px) and (orientation: portrait) {
  .divHeader {
    padding: 20px 0;
  }
  .imageLogo {
    width: auto;
    height: 20px;
  }
  .divForm {
    width: 100%;
  }
  .textRegular24 {
    font-size: 18px;
  }
  .textRegular18 {
    font-size: 14px;
  }
  .textRegular16 {
    font-size: 12px;
  }
  .textRegular14 {
    font-size: 10px;
  }
  .textLinkRegular14 {
    font-size: 10px;
  }
  .textGray14 {
    font-size: 10px;
  }
}

/* <!--Landscape mode of a smart phone and a small tablet--> */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .divForm {
    width: 100%;
  }
}

/* <!--Tablet and a small laptop--> */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* <!--PC browser--> */
/* @media screen and (min-width: 1024px) {} */
