/* coloring guidance */

:root {
  --Primary-100: #00539b;
  --Secondary-60: #1263d4;
  --Secondary-10: #ecf5ff;
  --Foundation-200: #3c3c3c;
  --Foundation-100: #fff;
  --Border-10: #cfdfec;
  --Grey-60: #707070;
  --Grey-50: #999;
  --Grey-20: #e6e6e6;
  --Grey-10: #f0f0f0;
  --Red-50: #eb5757;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.containerModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;
}

.divButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 16px 0;
}

.divPaginationNumber {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.divListItem {
  padding: 32px;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  width: 300px;
}

.divFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.divRowLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
}

.divRowRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
}

.divColumnNumber {
  display: flex;
  flex-direction: column;
}

.divTabPanelContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.divGroupButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.divContentModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.divCard {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.divIconEnd {
  display: flex;
  width: 10%;
  justify-content: flex-end;
}

.divCloseEnd {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.card {
  width: 100%;
  /* width: 33%; */
  box-shadow: none !important;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-10);
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: self-start;
}

.cardData {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detailCard {
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.detailTextCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.boxModal {
  display: flex;
  width: 470px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background: var(--Foundation-100);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tabPanel {
  min-height: 0 !important;
}

.tabItem {
  min-height: 0 !important;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px !important;
  border: 1px solid var(--Border-10) !important;
  overflow: hidden;
  color: var(--Grey-60) !important;
  text-overflow: ellipsis;
  margin: 0 12px 0 0;

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  text-transform: none;
}

.tabItemActive {
  min-height: 0 !important;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px !important;
  border: 1px solid var(--Border-10) !important;
  background: var(--Grey-10) !important;
  overflow: hidden;
  color: var(--Foundation-200) !important;
  text-overflow: ellipsis;
  margin: 0 12px 0 0;

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  text-transform: none;
}

.selectStatus {
  display: flex;
  /* width: 180px; */
  height: 38px;
  padding: 8px 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.statusMenuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectedInput {
  padding-right: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  gap: 12px;
}
.customIcon {
  cursor: pointer;
}

.imageLogo {
  width: auto;
  height: 40px;
  flex-shrink: 0;
}

.listItemLanguage {
  padding: 12px;
  width: 150px;
}

.languageButton {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  text-transform: none;
}
.languageButton:hover,
.languageButton:active,
.languageButton:focus {
  background-color: transparent;
  box-shadow: none;
  color: var(--Foundation-100);
}

.itemLanguageButton {
  /* Inter/Body Text/Regular/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  border-radius: 8px;
}

.multipleOptions {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.outlinedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--Primary-100);
  background-color: var(--Foundation-100);
  text-transform: none;

  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/14 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;

  &:hover {
    background: var(--Foundation-100);
  }
}

.outlinedButtonSmall {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: auto;
  align-self: center;
  border-radius: 8px;
  border: 1px solid var(--Primary-100);
  background-color: var(--Foundation-100);
  text-transform: none;

  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;

  &:hover {
    background: var(--Foundation-100);
  }
}

.outlinedPaginationButton {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--Grey-10);
  background-color: var(--Foundation-100);
  text-transform: none;

  color: var(--Foundation-200);
  text-align: center;

  /* Inter/Body Text/Medium/12 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */

  &:hover {
    background: var(--Foundation-100);
  }
}

.containedPaginationButton {
  display: flex;
  min-width: 32px;
  min-height: 32px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px !important;
  background: var(--Grey-10) !important;

  color: var(--Foundation-200) !important;
  text-align: center;

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;

  &:hover {
    background: var(--Grey-10);
  }
}

.transparentPaginationButton {
  display: flex;
  min-width: 32px;
  min-height: 32px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: var(--Foundation-100) !important;

  color: var(--Foundation-200) !important;
  text-align: center;

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;

  &:hover {
    background: var(--Foundation-100) !important;
  }
}

.rowPagination {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 2px;
  flex-grow: 1;
  flex-wrap: wrap;
}

.numberPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.containedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Primary-100);
  text-transform: capitalize;
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;

  &:hover {
    background: var(--Primary-100);
  }
}

.containedButtonSmall {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: auto;
  align-self: center;
  border-radius: 8px;
  background: var(--Primary-100);
  text-transform: capitalize;
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;

  &:hover {
    background: var(--Primary-100);
  }
}

.searchField {
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
  padding: 0;
}

.inputSearchField {
  color: var(--Foundation-200, #3c3c3c);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
  padding: 8px 12px;
}

.inputSearchField::placeholder {
  color: var(--Grey-50, #999);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.inputSearchOnly {
  padding: 0;
}

.fieldUploadFile {
  border-radius: 8px;
  border: 1px dashed var(--Border-10);
  background: var(--Foundation-100);
  display: flex;
  height: 42px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
}

.inputUpload {
  cursor: pointer;
}

.noBorder {
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.labelSelectOptions {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.084px;
}

.disabledContainedButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Grey-20);
  text-transform: capitalize;

  &:hover {
    background: var(--Grey-20);
  }
}

.selectControl {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border-10) !important;
}

.buttonExport {
  display: flex;
  height: 38px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
  text-transform: none;

  color: var(--Foundation-200, #3c3c3c);
  text-align: center;

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.listItemButton {
  padding-left: 0;
}

.textOutlinedButton {
  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textContainedButton {
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textDisabledContainedButton {
  color: var(--Grey-50);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textBold20 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold18 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold16 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textBold12 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textSemiBold14 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Medium/14 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textRegular24 {
  color: var(--Foundation-200);

  /* Inter/Header/Semi Bold/24 */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
}

.textRegular18 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Medium/18 */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.252px;
}

.textRegular16 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textRegular14 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.disabledButton {
  color: var(--Foundation-200) !important;
  font-size: 14px;
}

.textLinkPrimary16 {
  color: var(--Primary-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  text-decoration: none;
}

.textLinkSemiBold14 {
  color: var(--Foundation-200);

  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.084px;
  text-decoration: none;
  opacity: 1;
  border: none;
}

.textLinkSemiBold14 ::placeholder {
  color: var(--Secondary-60);

  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.084px;
  text-decoration: none;
  opacity: 1;
}

.textLinkRegular14 {
  color: var(--Secondary-60);

  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.084px;
  text-decoration: none;
}

.textGray14 {
  color: var(--Grey-50);
  text-align: center;

  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textAlert12 {
  color: var(--Red-50);
  text-align: center;

  /* Inter/Body/Regular/14 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.field {
  display: flex;
  /* height: 48px; */
  padding: 14px 12px;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.placeholder {
  color: var(--Grey-60);
  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.tooltipStyle {
  color: var(--Secondary-60);
  background-color: var(--Secondary-10);
  /* Inter/Body/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.visibilityIcon {
  color: var(--Secondary-60);
  background-color: transparent;
}

.tableHead {
  border-top: 1px solid var(--Border-10);
  border-bottom: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.tableRow {
  border-bottom: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.tableCellFullBorder {
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.textBold14 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.buttonAction {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Secondary-10);
  text-transform: none;
  color: var(--Primary-100);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.divBoxDrawer {
  margin: 100px 0 0 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 550px;
}
.paddingDrawer {
  padding: 24px;
}

.textBold36 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.textBold16 {
  color: var(--Foundation-200);
  /* Inter/Body Text/Regular/14 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.leaflet_container {
  height: 300px;
  width: 90%;
}

.buttonFilterDate {
  display: flex;
  height: 38px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.placeholderFilterDate {
  color: var(--Grey-50);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.084px;
  text-transform: capitalize;
}

.optionMenu {
  width: 371px;
  height: 355px;
  padding: 12px;
  border-radius: 8px;
  gap: 20px;
  background-color: var(--Foundation-100);
  box-shadow: 0px -4px 15px 0px #0000000d;
  /* padding: 0; */
  border-color: none;
}

.listMenu {
  padding: 0;
}

.containerFilterDate {
  display: flex;
  width: 380px;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05),
    0px -4px 15px 0px rgba(0, 0, 0, 0.05);
}

.textBoldInter15 {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.text500Inter14 {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.084px;
}
.radioButton {
  width: 10.71px;
  height: 10.71px;
  top: 3.14px;
  left: 3.14px;
  color: var(--Border-10);
}
.textGray16Color60 {
  color: var(--Grey-60);

  /* Inter/Body Text/Medium/16 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textGray14Color60 {
  color: var(--Grey-60);

  /* Inter/Body Text/Medium/16 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.textGray12Color60 {
  color: var(--Grey-60);

  /* Inter/Body Text/Medium/16 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.labelFilterDate {
  color: var(--Foundation-200);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.084px;
}

.notesCustomDate {
  color: var(--Grey-60);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 6px 0 6px 0;
}

.buttonApplyFilterDate {
  border-radius: 8px;
  background: var(--Primary-100);
  display: flex;
  height: 48px;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: 20px;
  height: 43px;
  &:hover {
    background: var(--Primary-100);
  }
}

.textApplyFilter {
  color: var(--Foundation-100);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.176px;
  text-transform: capitalize;
}

.flexCustomDate {
  display: flex;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
}

.flexColumCustomDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.styleDateCustom {
  border-radius: 8px;
  border: 1px solid var(--Border-10);
}

.redCircle {
  display: flex;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 1000px;
  background: #fde7e7;
  width: fit-content;
}

.divDialogEdit {
  padding: 24px;
}

.divForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 450px;
}

.divFormField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.descriptionField {
  display: flex;
  height: 128px;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Border-10);
  background: var(--Foundation-100);
}

.containedFormButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Primary-100);
  text-transform: capitalize;
  color: var(--Foundation-100);

  /* Inter/Body Text/Semi Bold/16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;

  &:hover {
    background: var(--Primary-100);
  }
}

.deleteButton {
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  text-transform: none;

  color: var(--Error-50);
  /* Inter/Body Text/Semi Bold/16 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;

  &:hover {
    background: none;
  }
}

/* <!--Portrait mode of a smart phone--> */
@media screen and (max-width: 480px) and (orientation: portrait) {
  .divHeader {
    padding: 20px 0;
  }
  .imageLogo {
    width: auto;
    height: 20px;
  }
  .divForm {
    width: 100%;
  }
  .textRegular24 {
    font-size: 18px;
  }
  .textRegular18 {
    font-size: 14px;
  }
  .textRegular16 {
    font-size: 12px;
  }
  .textRegular14 {
    font-size: 10px;
  }
  .textLinkRegular14 {
    font-size: 10px;
  }
  .textGray14 {
    font-size: 10px;
  }
  .languageButton {
    gap: 4px;
  }
}

/* <!--Landscape mode of a smart phone and a small tablet--> */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .divForm {
    width: 100%;
  }
}

/* <!--Tablet and a small laptop--> */
@media screen and (min-width: 768px) and (max-width: 1024px) {
}

/* <!--PC browser--> */
/* @media screen and (min-width: 1024px) {} */
